import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel, DateTimeOffset, Transform
} from '@nts/std';
import { EditExpenseAnnotationParamsIdentity } from '../../domain-models/edit-expense-annotation-params.identity';
import { ExpenseItemCollection } from '../../domain-models/expense-item.collection';
import { ExpenseItem } from '../../domain-models/expense-item';
import { AnnotationState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-annotation-state';
import { Currency } from 'src/app/external-remote/currency/currency';
import { CurrencyIdentity } from 'src/app/external-remote/currency/currency.identity';
import { ExpenseAnnotationNote } from '../../domain-models/expense-annotation-note';

@IdentityTypeDecorator(EditExpenseAnnotationParamsIdentity)
@ModelTypeNameDecorator('EditExpenseAnnotationParams')
export class GeneratedEditExpenseAnnotationParams extends CoreModel<EditExpenseAnnotationParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _expenseAnnotationId: number = 0;

	/**
	 Id nota spese
	 */
	@Expose()
	public get expenseAnnotationId(): number {
		return this.getValue<number>(() => this._expenseAnnotationId, 'expenseAnnotationId');
	}
	public set expenseAnnotationId(value: number) {
		this.setValue<number>(() => { this._expenseAnnotationId = value; }, value, 'expenseAnnotationId');
	}

	private _annotationState: AnnotationState;

	/**
	 Stato nota spese
	 */
	@Expose()
	public get annotationState(): AnnotationState {
		return this.getValue<AnnotationState>(() => this._annotationState, 'annotationState');
	}
	public set annotationState(value: AnnotationState) {
		this.setValue<AnnotationState>(() => { this._annotationState = value; }, value, 'annotationState');
	}

	private _modelDescription: string;

	/**
	 Descrizione modello
	 */
	@Expose()
	public get modelDescription(): string {
		return this.getValue<string>(() => this._modelDescription, 'modelDescription');
	}
	public set modelDescription(value: string) {
		this.setValue<string>(() => { this._modelDescription = value; }, value, 'modelDescription');
	}

	private _leadId: number;

	/**
	 Id lead
	 */
	@Expose()
	public get leadId(): number {
		return this.getValue<number>(() => this._leadId, 'leadId');
	}
	public set leadId(value: number) {
		this.setValue<number>(() => { this._leadId = value; }, value, 'leadId');
	}

	private _leadCompanyId: number;

	/**
	 Codice ditta lead
	 */
	@Expose()
	public get leadCompanyId(): number {
		return this.getValue<number>(() => this._leadCompanyId, 'leadCompanyId');
	}
	public set leadCompanyId(value: number) {
		this.setValue<number>(() => { this._leadCompanyId = value; }, value, 'leadCompanyId');
	}

	private _customerId: number;

	/**
	 Id cliente
	 */
	@Expose()
	public get customerId(): number {
		return this.getValue<number>(() => this._customerId, 'customerId');
	}
	public set customerId(value: number) {
		this.setValue<number>(() => { this._customerId = value; }, value, 'customerId');
	}

	private _customerCompanyId: number;

	/**
	 Codice ditta cliente
	 */
	@Expose()
	public get customerCompanyId(): number {
		return this.getValue<number>(() => this._customerCompanyId, 'customerCompanyId');
	}
	public set customerCompanyId(value: number) {
		this.setValue<number>(() => { this._customerCompanyId = value; }, value, 'customerCompanyId');
	}

	private _commissionId: number;

	/**
	 Id commessa
	 */
	@Expose()
	public get commissionId(): number {
		return this.getValue<number>(() => this._commissionId, 'commissionId');
	}
	public set commissionId(value: number) {
		this.setValue<number>(() => { this._commissionId = value; }, value, 'commissionId');
	}

	private _commissionCompanyId: number;

	/**
	 Codice ditta commessa
	 */
	@Expose()
	public get commissionCompanyId(): number {
		return this.getValue<number>(() => this._commissionCompanyId, 'commissionCompanyId');
	}
	public set commissionCompanyId(value: number) {
		this.setValue<number>(() => { this._commissionCompanyId = value; }, value, 'commissionCompanyId');
	}

	private _startDate: DateTimeOffset;

	/**
	 Data inizio
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('startDate', value, obj, type);
	})
	public get startDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._startDate, 'startDate');
	}
	public set startDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._startDate = value; }, value, 'startDate');
	}

	private _currencyId: number;

	/**
	 Id valuta
	 */
	@Expose()
	public get currencyId(): number {
		return this.getValue<number>(() => this._currencyId, 'currencyId');
	}
	public set currencyId(value: number) {
		this.setValue<number>(() => { this._currencyId = value; }, value, 'currencyId');
	}

	private _currencyRef: Currency;

	/**
	 Valuta
	 */
	@Expose()
	@Type(() => Currency)
	public get currencyRef(): Currency {
		return this.getValueForExternal<Currency>(
			() => this._currencyRef,
			'currencyRef',
			Currency
		);
	}
	public set currencyRef(value: Currency) {
		this.setValueForExternal<Currency, CurrencyIdentity>(() => { this._currencyRef = value; }, value, 'currencyRef');
	}

	private _totalCosts: number = 0;

	/**
	 Totale costi
	 */
	@Expose()
	public get totalCosts(): number {
		return this.getValue<number>(() => this._totalCosts, 'totalCosts');
	}
	public set totalCosts(value: number) {
		this.setValue<number>(() => { this._totalCosts = value; }, value, 'totalCosts');
	}

	private _totalRefound: number = 0;

	/**
	 Totale rimborsi
	 */
	@Expose()
	public get totalRefound(): number {
		return this.getValue<number>(() => this._totalRefound, 'totalRefound');
	}
	public set totalRefound(value: number) {
		this.setValue<number>(() => { this._totalRefound = value; }, value, 'totalRefound');
	}

	private _endDate: DateTimeOffset;

	/**
	 Data chiusura
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('endDate', value, obj, type);
	})
	public get endDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._endDate, 'endDate');
	}
	public set endDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._endDate = value; }, value, 'endDate');
	}

	private _description: string;

	/**
	 Descrizione nota spese
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	private _expenses: ExpenseItemCollection;

	/**
	 Lista spese
	 */
	@Expose()
	@Type(() => ExpenseItemCollection)
	public get expenses(): ExpenseItemCollection {
		return this.getValueForInternalCollection<ExpenseItemCollection, ExpenseItem>(
			(value) => {
				this._expenses = value;
			},
			this._expenses,
			'expenses',
			ExpenseItemCollection
		);
	}
	public set expenses(value: ExpenseItemCollection) {
		this.setValueForInternalCollection<ExpenseItemCollection, ExpenseItem>(() => { this._expenses = value; }, value, 'expenses');
	}

	private _expenseModelId: number;

	/**
	 Id modello nota spese
	 */
	@Expose()
	public get expenseModelId(): number {
		return this.getValue<number>(() => this._expenseModelId, 'expenseModelId');
	}
	public set expenseModelId(value: number) {
		this.setValue<number>(() => { this._expenseModelId = value; }, value, 'expenseModelId');
	}

	private _note: ExpenseAnnotationNote;

	/**
	 Note
	 */
	@Expose()
	@Type(() => ExpenseAnnotationNote)
	public get note(): ExpenseAnnotationNote {
		return this.getValueForInternal<ExpenseAnnotationNote>(
			(value) => {
				this._note = value;
			},
			this._note,
			'note',
			ExpenseAnnotationNote
		);
	}
	public set note(value: ExpenseAnnotationNote) {
		this.setValueForInternal<ExpenseAnnotationNote>(() => { this._note = value; }, value, 'note');
	}

	private _hasAlert: boolean;

	/**
	 Errore presente
	 */
	@Expose()
	public get hasAlert(): boolean {
		return this.getValue<boolean>(() => this._hasAlert, 'hasAlert');
	}
	public set hasAlert(value: boolean) {
		this.setValue<boolean>(() => { this._hasAlert = value; }, value, 'hasAlert');
	}

	private _isActive: boolean;

	/**
	 Attiva
	 */
	@Expose()
	public get isActive(): boolean {
		return this.getValue<boolean>(() => this._isActive, 'isActive');
	}
	public set isActive(value: boolean) {
		this.setValue<boolean>(() => { this._isActive = value; }, value, 'isActive');
	}
}
