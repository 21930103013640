import { NNumericPropertyViewModel, RequiredValidation, SourceMessage } from "@nts/std";
import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { GeneralSubjectIdentity } from "src/app/external-remote/general-subject/general-subject.identity";
import { Supplier } from "src/app/external-remote/supplier/supplier";

@IdentityTypeDecorator(GeneralSubjectIdentity)
@ExternalDomainModelTypeDecorator(Supplier)
@ExternalDomainModelTypeNameDecorator('Supplier')
export class SupplierExtViewModel extends ExternalViewModel<Supplier, GeneralSubjectIdentity> {

    override backingFieldCanNotifyModified = false;

    // tslint:disable-next-line: variable-name
    private _id: NNumericPropertyViewModel;

    get id(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    private _companyId: NumericPropertyViewModel;

    public get companyId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._companyId = value; }, this._companyId, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _companyName: StringPropertyViewModel;

    get companyName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._companyName = value; }, this._companyName, 'companyName');
    }

    // tslint:disable-next-line: variable-name
    private _vatNumber: StringPropertyViewModel;

    get vatNumber(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._vatNumber = value; }, this._vatNumber, 'vatNumber');
    }
    // tslint:disable-next-line: variable-name
    private _fiscalCode: StringPropertyViewModel;

    get fiscalCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._fiscalCode = value; }, this._fiscalCode, 'fiscalCode');
    }
    // tslint:disable-next-line: variable-name
    private _city: StringPropertyViewModel;

    get city(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._city = value; }, this._city, 'city');
    }
    // tslint:disable-next-line: variable-name
    private _zipCode: StringPropertyViewModel;

    get zipCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._zipCode = value; }, this._zipCode, 'zipCode');
    }
    // tslint:disable-next-line: variable-name

    override async postInit() {

        await super.postInit();

        this.zoomAddIsEnabled = false;
        this.zoomAddIsVisible = false;
        this.zoomSearchIsEnabled = true;
        this.zoomSearchIsVisible = true;
        this.zoomViewIsEnabled = false;
        this.zoomViewIsVisible = false;

        this.searchProperties = ['Id', 'CompanyName'];
        this.decodeProperties = ['Id', 'CompanyName'];
    }

}
