import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel, DateTimeOffset, Transform
} from '@nts/std';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { ExpenseType } from 'src/app/expense-type/domain-models/expense-type';
import { ExpenseTypeIdentity } from 'src/app/expense-type/domain-models/expense-type.identity';
import { ExpenseItemIdentity } from '../../domain-models/expense-item.identity';

@IdentityTypeDecorator(ExpenseItemIdentity)
@ModelTypeNameDecorator('ExpenseItem')
export class GeneratedExpenseItem extends CoreModel<ExpenseItemIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _expenseId: number = 0;

	/**
	 Id spesa
	 */
	@Expose()
	public get expenseId(): number {
		return this.getValueForIdentityValue<number>(() => this._expenseId, 'expenseId');
	}
	public set expenseId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._expenseId = value; }, value, 'expenseId');
	}

	private _expenseTypeId: number;

	/**
	 Id tipo spesa
	 */
	@Expose()
	public get expenseTypeId(): number {
		return this.getValue<number>(() => this._expenseTypeId, 'expenseTypeId');
	}
	public set expenseTypeId(value: number) {
		this.setValue<number>(() => { this._expenseTypeId = value; }, value, 'expenseTypeId');
	}

	private _expenseTypeRef: ExpenseType;

	/**

	 */
	@Expose()
	@Type(() => ExpenseType)
	public get expenseTypeRef(): ExpenseType {
		return this.getValueForExternal<ExpenseType>(
			() => this._expenseTypeRef,
			'expenseTypeRef',
			ExpenseType
		);
	}
	public set expenseTypeRef(value: ExpenseType) {
		this.setValueForExternal<ExpenseType, ExpenseTypeIdentity>(() => { this._expenseTypeRef = value; }, value, 'expenseTypeRef');
	}

	private _expenseDate: DateTimeOffset;

	/**
	 Data spesa
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('expenseDate', value, obj, type);
	})
	public get expenseDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._expenseDate, 'expenseDate');
	}
	public set expenseDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._expenseDate = value; }, value, 'expenseDate');
	}

	private _expenseAmount: number = 0;

	/**
	 Totale spesa
	 */
	@Expose()
	public get expenseAmount(): number {
		return this.getValue<number>(() => this._expenseAmount, 'expenseAmount');
	}
	public set expenseAmount(value: number) {
		this.setValue<number>(() => { this._expenseAmount = value; }, value, 'expenseAmount');
	}

	private _expenseState: ExpenseState;

	/**
	 Stato spesa
	 */
	@Expose()
	public get expenseState(): ExpenseState {
		return this.getValue<ExpenseState>(() => this._expenseState, 'expenseState');
	}
	public set expenseState(value: ExpenseState) {
		this.setValue<ExpenseState>(() => { this._expenseState = value; }, value, 'expenseState');
	}

	private _businessName: string;

	/**
	 Ragione sociale fornitore
	 */
	@Expose()
	public get businessName(): string {
		return this.getValue<string>(() => this._businessName, 'businessName');
	}
	public set businessName(value: string) {
		this.setValue<string>(() => { this._businessName = value; }, value, 'businessName');
	}

	private _refoundedAmount: number = 0;

	/**
	 Totale rimborsato
	 */
	@Expose()
	public get refoundedAmount(): number {
		return this.getValue<number>(() => this._refoundedAmount, 'refoundedAmount');
	}
	public set refoundedAmount(value: number) {
		this.setValue<number>(() => { this._refoundedAmount = value; }, value, 'refoundedAmount');
	}

	private _paymentModeDescription: string;

	/**
	 Modalità di pagamento
	 */
	@Expose()
	public get paymentModeDescription(): string {
		return this.getValue<string>(() => this._paymentModeDescription, 'paymentModeDescription');
	}
	public set paymentModeDescription(value: string) {
		this.setValue<string>(() => { this._paymentModeDescription = value; }, value, 'paymentModeDescription');
	}

	private _expenseNotes: string;

	/**
	 Note spesa
	 */
	@Expose()
	public get expenseNotes(): string {
		return this.getValue<string>(() => this._expenseNotes, 'expenseNotes');
	}
	public set expenseNotes(value: string) {
		this.setValue<string>(() => { this._expenseNotes = value; }, value, 'expenseNotes');
	}

	private _mileageQuantity: number;

	/**
	 Quantità
	 */
	@Expose()
	public get mileageQuantity(): number {
		return this.getValue<number>(() => this._mileageQuantity, 'mileageQuantity');
	}
	public set mileageQuantity(value: number) {
		this.setValue<number>(() => { this._mileageQuantity = value; }, value, 'mileageQuantity');
	}
}
