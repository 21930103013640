import { SafeResourceUrl } from '@angular/platform-browser';
import { OnlineService } from '@nts/std/src/lib/utility';
import { EnumPropertyViewModel, ExternalViewModelTypeDecorator, MessageResourceManager, NNumericPropertyViewModel, SourceMessage } from '@nts/std';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { CommissionIdentity } from 'src/app/external-remote/commission/commission.identity';
import { GeneralSubjectIdentity } from 'src/app/external-remote/general-subject/general-subject.identity';
import { LeadIdentity } from 'src/app/external-remote/lead/lead.identity';
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { ColorUtility } from 'src/app/shared/services/color-utility';
import { ExpenseClassificationMapper } from 'src/app/shared/services/expense-classification-mapper';
import { ExpenseStateMapper } from 'src/app/shared/services/expense-state-mapper';
import { ExpenseClassification } from '../generated/domain-models/enums/generated-expense-classification';
import { GeneratedExpenseDataViewModel } from '../generated/view-models/generated-expense-data.view-model';
import { ReceiptLongOpOrchestratorViewModel } from './receipt-long-op.orchestrator-view-model';
import { ErrorManager } from '../services/errorr-manager.service';
import { ReceiptParamsViewModel } from './receipt-params.view-model';
import { WeaSettingsDataDto } from 'src/app/create-expense-annotation-long-op/api-clients/dto/wea-settings-data.dto';
import { LeadExtViewModel } from './lead.ext-view-model';
import { CustomerExtViewModel } from './customer.ext-view-model';
import { CommissionExtViewModel } from './commission.ext-view-model';
import { ExtendedAvailableExpenseExtViewModel } from './extended-available-expense.ext-view-model';
import { ExtendedAvailableExpense } from '../domain-models/extended-available-expense';

export class ExpenseDataViewModel extends GeneratedExpenseDataViewModel implements StepViewModelAwareInterface {

    @ExternalViewModelTypeDecorator(LeadExtViewModel)
    public leadRef: LeadExtViewModel;

    @ExternalViewModelTypeDecorator(CustomerExtViewModel)
    public customerRef: CustomerExtViewModel;

    @ExternalViewModelTypeDecorator(CommissionExtViewModel)
    public commissionRef: CommissionExtViewModel;

    currentImageModalSrc: SafeResourceUrl = null;
    currentColor = null;
    currentHoverColor = null;
    currentActiveColor = null;
    currentExpenseClassificationType: ExpenseClassification;
    expenseClassificationEnum = ExpenseClassification;
    isValid = new BehaviorSubject<boolean>(false);
    hasActionsButtons = new BehaviorSubject<boolean>(true);
    isPreviousDisabled = new BehaviorSubject<boolean>(false);
    hideNext = new BehaviorSubject<boolean>(false);
    fullExpenseModel: ExpenseModel = null;
    extendedAvailableExpenses: ExtendedAvailableExpense[] = [];
    isRemoteUpload = false;
    expenseState: EnumPropertyViewModel;
    expenseStateMapper = ExpenseStateMapper;
    showCurrentState: boolean;
    isReadOnly = false;
    configuration: WeaSettingsDataDto;
    onlineService: OnlineService;
    allFilesUploaded: boolean = true

    private _extendedExpenseTypeId: NNumericPropertyViewModel;

    public get extendedExpenseTypeId(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._extendedExpenseTypeId = value; }, this._extendedExpenseTypeId, 'extendedExpenseTypeId');
    }

    @ExternalViewModelTypeDecorator(ExtendedAvailableExpenseExtViewModel)
    public extendedExpenseTypeRef: ExtendedAvailableExpenseExtViewModel;

    async initPresentationLogic(
        expenseClassificationType: ExpenseClassification,
        fullExpenseModel: ExpenseModel,
        extendedAvailableExpenses: ExtendedAvailableExpense[],
        expenseAnnotationId: number,
        remoteExpense: boolean,
        currencySymbol: string = null,
        expenseState: EnumPropertyViewModel,
        configuration: WeaSettingsDataDto,
        customerIdentity: GeneralSubjectIdentity,
        leadIdentity: LeadIdentity,
        commissionIdentity: CommissionIdentity,
        onlineService: OnlineService,
    ) {

        if (extendedAvailableExpenses.length == 0) {
            await this.extendedExpenseTypeRef.setCodeValue(null);
        } else {
            if (this.extendedExpenseTypeRef?.id?.value > 0) {
                const found = extendedAvailableExpenses.find((eap) => eap.id === this.extendedExpenseTypeRef?.id?.value)
                if (!found) {
                    await this.extendedExpenseTypeRef.setCodeValue(null);
                }
            }
        }

        this.showCurrentState = expenseAnnotationId > 0 && remoteExpense === true;
        this.currentExpenseClassificationType = expenseClassificationType;
        this.currentColor = ColorUtility.shade(ExpenseClassificationMapper.getColorByClassificationType(expenseClassificationType), -0.1);
        this.currentActiveColor = ColorUtility.shade(this.currentColor, -0.1)
        this.currentHoverColor = ColorUtility.shade(this.currentColor, -0.3)
        this.isRemoteUpload = expenseAnnotationId > 0;
        this.expenseState = expenseState;
        this.configuration = configuration;
        this.onlineService = onlineService;
        this.fullExpenseModel = fullExpenseModel;
        // #5331 - per attivare lo sblocco del tasto continua appena si modifica e senza uscire dal campo.
        // ATTENZIONE, instantModelChange crea problemi con inputMask, perchè valida appena premi un tasto, non usarlo
        // this.expenseTotalAmount.instantModelChange = true;


        if (currencySymbol) {
            this.expenseTotalAmount.metadataDescription = MessageResourceManager.Current.getMessage(this.expenseTotalAmount.propertyMetaData.descriptions.descriptionKey) + ' (' + currencySymbol + ')';
            this.expenseTotalAmount.metadataShortDescription = MessageResourceManager.Current.getMessage(this.expenseTotalAmount.propertyMetaData.descriptions.displayNameKey) + ' (' + currencySymbol + ')';
        }

        this.isReadOnly = !(this.expenseState.value === ExpenseState.WebEditing || this.expenseState.value === ExpenseState.IntegrateDocumentation);

        if (this.isReadOnly) {
            this.expenseDate.isEnabled = !this.isReadOnly;
            if (this.extendedExpenseTypeRef) {
                this.extendedExpenseTypeRef.isEnabled = !this.isReadOnly;
            }
            this.description.isEnabled = !this.isReadOnly;
            this.expenseTotalAmount.isEnabled = !this.isReadOnly;
            this.note.isEnabled = !this.isReadOnly;
            this.files.isEnabled = !this.isReadOnly;
            this.expenseTypeDescription.isEnabled = !this.isReadOnly;
            this.leadRef.isEnabled = !this.isReadOnly;
            this.commissionRef.isEnabled = !this.isReadOnly;
            this.customerRef.isEnabled = !this.isReadOnly;

        }

        this.commissionRef.isVisible = commissionIdentity?.id == null;
        this.leadRef.isVisible = leadIdentity?.id == null;
        this.customerRef.isVisible = customerIdentity?.id == null;

        if (this.commissionRef.isVisible) {
            this.commissionRef.isVisible = configuration?.manageCommission === true;
            this.commissionRef.defaultColor = this.currentColor;
            this.commissionRef.activeColor = this.currentActiveColor;
            this.commissionRef.hoverColor = this.currentHoverColor;
        }

        if (this.leadRef.isVisible) {
            this.leadRef.isVisible = configuration?.manageLead === true;
            this.leadRef.defaultColor = this.currentColor;
            this.leadRef.activeColor = this.currentActiveColor;
            this.leadRef.hoverColor = this.currentHoverColor;
        }

        if (this.customerRef.isVisible) {
            this.customerRef.isVisible = configuration?.manageCustomer === true;
            this.customerRef.defaultColor = this.currentColor;
            this.customerRef.activeColor = this.currentActiveColor;
            this.customerRef.hoverColor = this.currentHoverColor;
        }

        this.expenseDate.defaultColor = this.currentColor;
        this.expenseDate.activeColor = this.currentActiveColor;
        this.expenseDate.hoverColor = this.currentHoverColor;

        if (this.extendedExpenseTypeRef) {
            this.extendedExpenseTypeRef.defaultColor = this.currentColor;
            this.extendedExpenseTypeRef.activeColor = this.currentActiveColor;
            this.extendedExpenseTypeRef.hoverColor = this.currentHoverColor;
        }

        this.description.defaultColor = this.currentColor;
        this.description.activeColor = this.currentActiveColor;
        this.description.hoverColor = this.currentHoverColor;

        this.expenseTotalAmount.defaultColor = this.currentColor;
        this.expenseTotalAmount.activeColor = this.currentActiveColor;
        this.expenseTotalAmount.hoverColor = this.currentHoverColor;

        this.note.defaultColor = this.currentColor;
        this.note.activeColor = this.currentActiveColor;
        this.note.hoverColor = this.currentHoverColor;

        this.files.defaultColor = this.currentColor;
        this.files.activeColor = this.currentActiveColor;
        this.files.hoverColor = this.currentHoverColor;

        this.extendedExpenseTypeRef.isRequired = this.extendedExpenseTypeRef.isEnabled;

        this.handleOfflineLogics();
    }

    checkFileValidation() {
        this.allFilesUploaded = this.files.every(file => (file.progress == null || file.progress == 100) && file.fileErrors.length == 0)
    }

    handleOfflineLogics() {
        this.onlineService.isOnline$.subscribe(() => {
            const isOnline = this.onlineService.isOnline;
            this.commissionRef.zoomSearchIsVisible = isOnline;
            this.customerRef.zoomSearchIsVisible = isOnline;
            this.leadRef.zoomSearchIsVisible = isOnline;

            this.commissionRef.zoomAddIsVisible = isOnline;
            this.customerRef.zoomAddIsVisible = isOnline;
            this.leadRef.zoomAddIsVisible = isOnline;

            this.commissionRef.zoomViewIsVisible = isOnline;
            this.customerRef.zoomViewIsVisible = isOnline;
            this.leadRef.zoomViewIsVisible = isOnline;
        })
    }

    openFileFullScreen(currentImageModalSrc: SafeResourceUrl) {
        this.currentImageModalSrc = currentImageModalSrc;
    }

    async validateStep(): Promise<void> {
        this.validate();
        this.checkFileValidation();
        //#5053 -> se stiamo aggiungendo una spesa che non è di tipo rimb. km allora il prezzo totale
        //deve essere > 0
        const parent = this.parent as ReceiptParamsViewModel;
        if (parent?.expenseSelection.expenseClassification.value != ExpenseClassification.MileageRefound) {
            if (this.expenseTotalAmount.value == undefined || this.expenseTotalAmount.value <= 0) {
                const errorObj = ErrorManager.getCustomError(
                    this.expenseTotalAmount.metadataDescription,
                    'expenseTotalAmount',
                    MessageResourceManager.Current.getMessage("ExpenseTotalAmountCannotBeZero"),
                    "MIN_VALUE_ERROR",
                )
                this.expenseTotalAmount.addError(SourceMessage.ViewModel, errorObj)
            }
        }
        const validExpenseType = this.extendedExpenseTypeRef?.id?.value != null && this.extendedExpenseTypeRef?.id?.value > 0;

        this.isValid.next(!this.hasErrors && validExpenseType && this.allFilesUploaded)

    };

    override async postInit(): Promise<void> {
        await super.postInit();

        if (this.extendedExpenseTypeRef) {
            this.extendedExpenseTypeRef.externalDomainModelChanged.subscribe(() => {
                if (this.extendedExpenseTypeRef?.description?.value?.length > 0) {
                    this.description.value = this.extendedExpenseTypeRef?.description?.value;
                }
            })
        }

        //proposta automatica di cliente in base al cambio commessa.
        this.commissionRef.externalDomainModelChanged.subscribe(async _ => {
            //se la commessa ha valore(il cambio potrebbe essere un annullamento)
            //lo faccio solo se il cliente è visibile -> se non è visibile vuol dire che non era
            //stato settato sulla nota spese e quindi non può essere settato dopo
            if (this.customerRef.isVisible && this.commissionId.value != undefined && this.commissionRef.customerId.value != undefined &&
                this.commissionRef.customerId.value != this.customerRef.id.value) {
                //setto il codevalue del cliente = a quello eventuale della commessa.
                await this.customerRef.setCodeValue(this.commissionRef.customerId.value)
            }
        })

        //proposta automatica di lead in base al cambio cliente.
        this.customerRef.externalDomainModelChanged.subscribe(async _ => {
            //se la commessa ha valore(il cambio potrebbe essere un annullamento)
            //lo faccio solo se il lead è visibile -> se non è visibile vuol dire che non era
            //stato settato sulla nota spese e quindi non può essere settato dopo
            if (this.leadRef.isVisible && this.customerId.value != undefined && this.customerRef.leadId.value != undefined &&
                this.customerRef.leadId.value != this.leadRef.id.value) {
                //setto il codevalue del cliente = a quello eventuale della commessa.
                await this.leadRef.setCodeValue(this.customerRef.leadId.value)
            }
        })

        //proposta automatica di lead in base al cambio cliente.
        this.leadRef.externalDomainModelChanged.subscribe(async _ => {
            //se il lead ha valore(il cambio potrebbe essere un annullamento)
            if (this.customerRef.isVisible && this.leadId.value != undefined) {
                const orchestrator = this.externalRetriever as ReceiptLongOpOrchestratorViewModel
                const response = await firstValueFrom(orchestrator.apiClient.getCustomerByLeadIdentity(this.leadRef?.domainModel?.currentIdentity))
                if (response.operationSuccedeed && response.result != undefined) {
                    //setto il codevalue del cliente = a quello eventuale della commessa.
                    await this.customerRef.setCodeValue(response.result.id)
                }
            }
        })
    }

    newLabels(): BehaviorSubject<any[]> {
        return (this.parent as ReceiptParamsViewModel).newLabels$;
    }
}
